import axios from 'axios';
import config from './config';

/**
 * @typedef {Object} SubtitleMeta
 * @property {string} created Timestamp of format "2020-11-24 14:45:40"
 * @property {number} created_by User id of creator of subtitles
 * @property {string} file_name File name of subtitles (including unescaped spaces and utf-8) with extension (ex. ".srt")
 * @property {string} label Language (or more)
 * @property {number} unique_media_id Unique media id of video this is attached to
 * @property {string} url Full S3 url to access the raw subtitles
 */

/**
 * @typedef {Object} StreamingProperties
 * @property {SubtitleMeta[]} subtitles
 * @property {Object} thumbnail_url_ssl Presigned thumbnail URLs (may expire)
 * @property {string} thumbnail_url_ssl.1600px Url to presigned & expiring cloudfront thumbnail in 1600px size
 */

/**
 * @returns {Promise<StreamingProperties>}
 */
export const getSubtitles = videoUniqueMediaId => axios.get(`${config.cbxApiUrl}/media/${videoUniqueMediaId}/stream/streaming_properties`, undefined, {
    withCredentials: true,
})
    .then(({ data }) => data);

export const getMediaType = (extension) => {
    switch (extension) {
        case 'mp4':
            return 'video/mp4';
        default:
            return 'application/x-mpegURL'; // m3u8;
    }
};
