import window from 'global/window';
import document from 'global/document';
import { getSubtitles, getMediaType } from './utils';

const params = new window.URLSearchParams(window.location.search);
const videoURL = params.get('v');
const videoUniqueMediaId = params.get('media');

const wrapper = document.getElementById('wrapper');
const isLocalStorageAvailable = () => {
    try {
        if (!window.localStorage) {
            return false;
        }
        const testKey = 'isLocalStorageAvailableTestKey';
        try {
            window.localStorage.setItem(testKey, 'test');
            window.localStorage.removeItem(testKey);
            return true;
        } catch {
            return false;
        }
    } catch {
        return false;
    }
};

if (videoURL) {
    const hasLocalStorage = isLocalStorageAvailable();
    const videoNode = document.createElement('video');
    videoNode.setAttribute('class', 'video-js vjs-big-play-centered');
    videoNode.setAttribute('playsinline', '');
    videoNode.setAttribute('crossorigin', 'anonymous');

    const regex = /(?:\.([^.]+))?$/;
    const fileExtension = regex.exec(videoURL)[1];
    const type = getMediaType(fileExtension);

    const options = {
        autoplay: false,
        controls: true,
        preload: 'auto',
        responsive: true,
        controlBar: {
            pictureInPictureToggle: false,
        },
        html5: {
            vhs: {
                useBandwidthFromLocalStorage: hasLocalStorage,
                limitRenditionByPlayerDimensions: false,
            },
        },
    };

    const sourceNode = document.createElement('source');
    sourceNode.src = videoURL;
    sourceNode.type = type;
    videoNode.appendChild(sourceNode);

    getSubtitles(videoUniqueMediaId)
        .then((response) => {
            if (response.subtitles) {
                response.subtitles.forEach((subtitle) => {
                    const trackElement = document.createElement('track');
                    trackElement.kind = 'subtitles';
                    trackElement.src = subtitle.url;
                    trackElement.label = subtitle.label;
                    videoNode.appendChild(trackElement);
                });
            }

            if (response.thumbnail_url_ssl?.['1600px']) {
                options.poster = response.thumbnail_url_ssl['1600px'];
            }
        })
        .catch(() => {
            // No subtitles
        })
        .finally(() => {
            wrapper.appendChild(videoNode);
            window.videojs(videoNode, options);
        });
} else {
    const textnode = document.createTextNode('No video URL provided');
    wrapper.appendChild(textnode);
}
